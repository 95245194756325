import create from 'zustand';
import axios from 'axios';
import { cmsApi } from 'services/CmsService';
import userStore from 'storage/user';

function context() {
  const { endpoint } = cmsApi.getState();

  const token = userStore.getToken();
  const auth = { headers: { Authorization: `Bearer ${token}` } };

  return { endpoint, auth };
}

export const [useEventStore, eventApi] = create((set, get) => ({
  instance: null,
  events: [],

  pick: async () => {
    let  event = get().instance;
    if(event) {
      return event;
    }
    const { endpoint } = context();
    const result = await axios.post(`${endpoint}/events/pick`);
    const instance = result.data ? result.data.event : null;
    set({ instance });
    return instance;
  },

  fetchEvents: async () => {
    const { endpoint, auth } = context();

    const result = await axios.get(`${endpoint}/events?_limit=-1&_sort=id&isHidden=false`, auth);

    set({ events: result.data });
  },

}));
