import React from 'react';
import { Box } from '@material-ui/core';
import MeaSync from './Synchronize/MeaSync';
import ZoomUserLink from './Synchronize/ZoomUserLink';
import TeamsUserLink from './Synchronize/TeamsUserLink';

const Synchronize = () => {
  return (
    <>
      <MeaSync />

      <Box mb={4} />

      <ZoomUserLink />

      <Box mb={1} />

      <TeamsUserLink />
    </>
  );
};

export default Synchronize;
