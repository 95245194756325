import React, { useState, useMemo } from 'react';
import { Button, Card, CardActions, CardHeader, CircularProgress, Divider, Typography } from '@material-ui/core';
import axios from 'axios';
import useCMS from '../../useCMS';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  loader: {
    margin: "10px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  consoleError: {
    "background-color": "#0c0c0cz",
    "padding": "0px 12px",
    "font-family": '"Menlo", "DejaVu Sans Mono", "Liberation Mono", "Consolas", "Ubuntu Mono", "Courier New", "andale mono", "lucida console", monospace',
    "color": "crimson"
  },
  console: {
    "background-color": "#0c0c0cz",
    "padding": "0px 12px",
    "font-family": '"Menlo", "DejaVu Sans Mono", "Liberation Mono", "Consolas", "Ubuntu Mono", "Courier New", "andale mono", "lucida console", monospace',
    "color": "#cccccc"
  }
}));

const ZoomUserLink = () => {
  const classes = useStyles();
  const { endpoint, auth } = useCMS();
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [statusText, setStatusText] = useState(null);
  const [error, setError] = useState(null);
  const [statusCompleted, setStatusCompleted] = useState([]);

  const updateStatus = async () => {
    const result = await axios.get(`${endpoint}/mea/zoom-user-link/status`, auth);

    return result.data;
  }

  const updateInterval = () => {
    const update = async () => {
      const result = await updateStatus();

      const updateText = () => {
        setStatusCompleted(result.completed);

        if (result.progress != null && result.length != null) {
          setStatusText(`${result.stage}: ${result.progress}/${result.length}...`);
        } else {
          setStatusText(`${result.stage}...`);
        }
      };

      switch (result.status) {
        case "pending":
          updateText();
          setTimeout(update, 1000);
          break;

        case "error":
          setLoading(false);
          setError(result.error);
          break;

        case "done":
        default:
          updateText();
          setLoading(false);
          //setStatusText(null);
          break;
      }

      setInitialized(true);
    }

    update();
  }

  useMemo(async () => {
    const result = await updateStatus();

    if (result.status === "done") {
      setLoading(false);
    } else {
      updateInterval();
    }
  }, []);

  const handleZoomUserLink = async () => {
    setError(null);
    setLoading(true);

    try {
      await axios.get(`${endpoint}/mea/zoom-user-link`, auth);

      updateInterval();
    } catch (e) {
      setLoading(false);

      if (e.response && e.response.status === 428) {
        setError(e.response.data.message);
      }
    }
  };

  return (
    <Card>
      <form>
        <CardHeader title="Zoom User Link" subheader="Link zoom users automatically." />

        <Divider />

        <CardActions>
          <Button color="primary" variant="outlined" onClick={handleZoomUserLink} disabled={loading}>
            Link zoom users
          </Button>
        </CardActions>

        <div style={{ display: (loading || statusText || error) && initialized ? "inherit" : "none" }}>
          <div style={{ backgroundColor: "black", paddingTop: "12px", paddingBottom: "12px" }}>
            {
              statusCompleted.map((status) => (
                <Typography key={status.line} className={classes.console} color="textSecondary" variant="body1">
                  {status.line}
                </Typography>)
              )
            }

            {loading && (
              <>
                <Typography className={classes.console} color="textSecondary" variant="body1">{statusText}</Typography>
                <div className={classes.loader}>
                  <CircularProgress color="primary" size={32} />
                </div>
              </>
            )}

            {error && (<Typography className={classes.consoleError} color="textSecondary" variant="body1">Error: {error}</Typography>)}
          </div>
        </div>

      </form>
    </Card>
  );
};

export default ZoomUserLink;
