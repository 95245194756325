import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Card, CardActions, CardContent, Divider, TextField, Grid, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import useCMS from '../useCMS';
import { PRODUCTION } from 'services/BuildService';
import { useEventStore } from 'services/EventService';
import debugStore from 'storage/debug';
import { hubApi } from '../../../services/HubService';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const DeleteChat = () => {
  const classes = useStyles();
  const { endpoint, auth } = useCMS();
  const events = useEventStore((state) => state.events);
  const fetchEvents = useEventStore((state) => state.fetchEvents);
  const [event, setEvent] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [responseText, setResponseText] = useState('');

  const syncEverything = async () => {
    if (window.confirm("Please confirm that you want to delete the all chats and no event is currently running.")) {

      const target = debugStore.getTarget();

      if (target !== PRODUCTION || window.confirm("It is the PRODUCTION environment. I do consent.")) {
        setResponseText('');
        setIsPending(true);

        await axios.post(`${endpoint}/chats/clear`, { eventId: event.id }, auth);
        await hubApi.getState().reloadClients();

        setIsPending(false);

        setResponseText(`Chats from the ${event.name} event successfully deleted.`)
      }
    }
  };

  const handleChange = (event, option) => {
    setEvent(option);
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <>
      <div>
        <Grid item md={12} xs={12}>
          <Typography style={{ padding: '16px 0' }} variant="h3">Delete Chat</Typography>
        </Grid>

        <Card className={classes.root}>
          <form autoComplete="off" noValidate>
            <Typography>Delete all chats in the database (event, private, support and group chats). Following this, we trigger a refresh signal to all connected clients.</Typography>

            <CardContent>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  options={events ?? []}
                  getOptionSelected={(option) => option.id === event.id}
                  getOptionLabel={(option) => option.name ?? ''}
                  onChange={handleChange}
                  disabled={isPending}
                  loading={!events}
                  renderInput={(params) => <TextField {...params} label="Choose Event" variant="outlined" />}
                />
              </Grid>
            </CardContent>

            <Divider />

            <CardActions>
              <Button color="primary" variant="contained" onClick={syncEverything} disabled={!event || isPending}>
                I Understand The Consequences
              </Button>

              {responseText && <Typography>{responseText}</Typography>}
            </CardActions>
          </form>
        </Card>
      </div>
    </>
  );
};

export default DeleteChat;
