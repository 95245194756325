import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Button, Card, CardActions, CardContent, Divider, TextField, Grid, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import useCMS from '../useCMS';
import { useEventStore } from 'services/EventService';
import { ExportToCsv } from 'export-to-csv';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const ExportChat = () => {
  const classes = useStyles();
  const { endpoint, auth } = useCMS();
  const events = useEventStore((state) => state.events);
  const fetchEvents = useEventStore((state) => state.fetchEvents);
  const [event, setEvent] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [responseText, setResponseText] = useState('');

  const handleChatExport = async () => {
    setIsPending(true);
    setResponseText('');

    try {
      const { data: chatMessages } = await axios.post(`${endpoint}/chats/export`, { eventId: event.id }, auth);

      if (!chatMessages.length) {
        throw new Error('No chat messages found.');
      }

      const date = moment(new Date()).format('YYYY-MM-DD');

      const csvExporter = new ExportToCsv({
        filename: `${date}-${event.name}-Chat-Export`,
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
      });

      csvExporter.generateCsv(chatMessages);

      setIsPending(false);
    } catch (error) {
      setResponseText(error.message);

      setIsPending(false);
    }
  };

  const handleChange = (event, option) => {
    setEvent(option);
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <>
      <div>
        <Grid item md={12} xs={12}>
          <Typography style={{ padding: '16px 0' }} variant="h3">Export Chat</Typography>
        </Grid>

        <Card className={classes.root}>
          <form autoComplete="off" noValidate>
            <Typography>Export all event chats for specific event.</Typography>

            <CardContent>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  options={events ?? []}
                  getOptionSelected={(option) => option.id === event.id}
                  getOptionLabel={(option) => option.name ?? ''}
                  onChange={handleChange}
                  disabled={isPending}
                  loading={!events}
                  renderInput={(params) => <TextField {...params} label="Choose Event" variant="outlined" />}
                />
              </Grid>
            </CardContent>

            <Divider />

            <CardActions>
              <Button color="primary" variant="contained" onClick={handleChatExport} disabled={!event || isPending}>
                Export Chat
              </Button>

              {responseText && <Typography>{responseText}</Typography>}
            </CardActions>
          </form>
        </Card>
      </div>
    </>
  );
};

export default ExportChat;
