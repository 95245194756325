import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import RouteWithLayout from './components/RouteWithLayout/RouteWithLayout';

import { useUserStore } from 'services/UserService';
import Dashboard from './views/Dashboard/Dashboard';
import Model from './views/Model/Model';
import Icons from './views/Icons/Icons';
import Typography from './views/Typography/Typography';
import Account from './views/Account/Account';
import Settings from './views/Settings/Settings';
import SynchronizeTool from './views/Tools/SynchronizeTool';
import DirectorTool from './views/Tools/DirectorTool';
import AdminTool from './views/Tools/AdminTool';
import SignIn from './views/SignIn/SignIn';
import NotFound from './views/NotFound/NotFound';
import Minimal from './layouts/Minimal/Minimal';
import Main from './layouts/Main/Main';

const Routes = () => {
  const isPending = useUserStore(state => state.loginState === 'PENDING');
  const isLoggedIn = useUserStore(state => state.loginState === 'LOGGED_IN');

  if (!isLoggedIn) {
    return (
      <Switch>
        <RouteWithLayout component={SignIn} exact layout={Minimal} path="/sign-in" />
        <RouteWithLayout component={Settings} exact layout={Main} path="/config" />
        {!isPending && <Redirect to="/sign-in" />}
      </Switch>
    );
  }

  return (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      <RouteWithLayout component={Dashboard} exact layout={Main} path="/dashboard" />
      <RouteWithLayout component={Model} exact layout={Main} path={['/teaser-pages/:id', '/teaser-pages']} modelName="teaser-pages" />
      <RouteWithLayout component={Model} exact layout={Main} path={['/teaser-events/:id', '/teaser-events']} modelName="teaser-events" />
      <RouteWithLayout component={Model} exact layout={Main} path={['/pre-event-pages/:id', '/pre-event-pages']} modelName="pre-event-pages" />
      <RouteWithLayout component={Model} exact layout={Main} path={['/events/:id', '/events']} modelName="events" />
      <RouteWithLayout component={Model} exact layout={Main} path={['/streams/:id', '/streams']} modelName="streams" />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/resource-hubs/:id', '/resource-hubs']}
        modelName="resource-hubs"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/resource-categories/:id', '/resource-categories']}
        modelName="resource-categories"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/resources/:id', '/resources']}
        modelName="resources"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/resource-images/:id', '/resource-images']}
        modelName="resource-images"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/resource-videos/:id', '/resource-videos']}
        modelName="resource-videos"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/resource-streams/:id', '/resource-streams']}
        modelName="resource-streams"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/resource-podcasts/:id', '/resource-podcasts']}
        modelName="resource-audios"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/resource-documents/:id', '/resource-documents']}
        modelName="resource-documents"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/resource-others/:id', '/resource-others']}
        modelName="resource-others"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/playgrounds/:id', '/playgrounds']}
        modelName="playgrounds"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/playground-resources/:id', '/playground-resources']}
        modelName="playground-resources"
      />
      <RouteWithLayout component={Model} exact layout={Main} path={['/users/:id', '/users']} modelName="users" />
      <RouteWithLayout component={Model} exact layout={Main} path={['/application']} modelName="application" />
      <RouteWithLayout component={Model} exact layout={Main} path={['/mea']} modelName="mea" />
      <RouteWithLayout component={Model} exact layout={Main} path={['/teams']} modelName="teams" />
      <RouteWithLayout component={Model} exact layout={Main} path={['/zoom']} modelName="zoom" />
      <RouteWithLayout component={Model} exact layout={Main} path={['/s-3']} modelName="s-3" />
      <RouteWithLayout component={Typography} exact layout={Main} path="/typography" />
      <RouteWithLayout component={Icons} exact layout={Main} path="/icons" />
      <RouteWithLayout component={Account} exact layout={Main} path="/account" />
      <RouteWithLayout component={Settings} exact layout={Main} path="/config" />
      <RouteWithLayout component={SynchronizeTool} exact layout={Main} path="/synchronize" />
      <RouteWithLayout component={AdminTool} exact layout={Main} path="/admin" />
      <RouteWithLayout component={DirectorTool} exact layout={Main} path="/director" />
      <RouteWithLayout component={AdminTool} exact layout={Main} path="/admin" />
      <RouteWithLayout component={NotFound} exact layout={Main} path="/not-found" />
      <Redirect exact from="/sign-in" to="/" />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
