import React, { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Hidden, IconButton, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import { userApi } from 'services/UserService';
import { PRODUCTION } from 'services/BuildService';
import debugStore from 'storage/debug';
import useCMS from '../../../../views/Tools/useCMS';
import { useApplicationStore } from 'services/ApplicationService';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  badge: {
    background: theme.palette.info.main,
    padding: '2px 5px',
    borderRadius: 3,
  },
  badgeWarning: {
    background: theme.palette.error.main,
  },
}));

const Title = 'Roland Berger Dashboard';

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;
  const { endpoint, auth } = useCMS();
  const { status, setStatus } = useApplicationStore();
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const target = debugStore.getTarget();
  const isProductionEnvironment = useMemo(() => target === PRODUCTION, [target]);

  const onLogout = () => {
    userApi.getState().logout();
    history.push('/');
  };

  const refreshStatus = async () => {
    const { data } = await axios.get(`${endpoint}/application/status`, auth);

    setStatus(data);
  };

  useEffect(() => {
    refreshStatus();
  }, [location]);

  const platformStatus = status.platformStatus || 'open';

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <div>
          <RouterLink to="/">
            <Typography style={{ color: 'white' }} variant={'h5'}>
              {isProductionEnvironment ? `⚠️ ${Title} (PROD) ⚠️` : Title }
            </Typography>
          </RouterLink>

          <div>
            <RouterLink to="/application">
              <Typography style={{ color: 'white', display: 'inline-block', marginRight: 10 }} variant={'h6'} className={clsx(classes.badge)}>
                Active event: {status.activeEvent}
              </Typography>

              <Typography style={{ color: 'white', display: 'inline-block', marginRight: 10 }} variant={'h6'} className={clsx(classes.badge)}>
                Status: {platformStatus}
              </Typography>

              {platformStatus === 'pre-event-page' && !status.isPreEventPageAssigned && (
                <RouterLink to="/application">
                  <Typography style={{ color: 'white', display: 'inline-block', marginRight: 10 }} variant={'h6'} className={clsx(classes.badge, classes.badgeWarning)}>
                    Please set a pre-event-page
                  </Typography>
                </RouterLink>
              )}

              <Typography style={{ color: 'white', display: 'inline-block', marginRight: 10 }} variant={'h6'} className={clsx(classes.badge)}>
                Video Service Provider: {status.videoCallService}
              </Typography>
            </RouterLink>

            {!status.isSynced && (
              <RouterLink to="/synchronize">
                <Typography style={{ color: 'white', display: 'inline-block' }} variant={'h6'} className={clsx(classes.badge, classes.badgeWarning)}>
                  Please sync all data
                </Typography>
              </RouterLink>
            )}
          </div>
        </div>

        <div className={classes.flexGrow} />
        <IconButton className={classes.signOutButton} color="inherit" onClick={onLogout}>
          <InputIcon />
        </IconButton>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
