import create from 'zustand';
import { cmsApi } from 'services/CmsService';
import axios from 'axios';
import userStore from 'storage/user';

export const [useTeamsStore, teamsApi] = create((set, get) => ({
  instance: {
    clientID: null,
    clientSecret: null,
    tenantID: null,
    jwt: null,
  },
  tokenIsGood: false,
  users: [],
  userId: null,

  get: async () => {
    const { endpoint } = cmsApi.getState();

    const token = userStore.getToken();
    const auth = { headers: { Authorization: `Bearer ${token}` } };
    const result = await axios.get(`${endpoint}/teams`, auth);
    const { data } = result;
    set({ instance: data });
    const { listUsers } = get();
    await listUsers();
    return data;
  },

  update: async payload => {
    const { endpoint } = cmsApi.getState();
    const token = userStore.getToken();
    const auth = { headers: { Authorization: `Bearer ${token}` } };
    const result = await axios.put(`${endpoint}/teams`, payload, auth);
    const { data } = result;
    set({ apiKey: data.apiKey, apiSecret: data.apiSecret, jwt: data.jwt });
  },

  listUsers: async jwt => {
    const { endpoint } = cmsApi.getState();
    const token = userStore.getToken();
    const auth = { headers: { Authorization: `Bearer ${token}` } };
    let users;
    try {
      const result = await axios.get(`${endpoint}/teams/users${jwt != null ? '?jwt=' + jwt : ''}`, auth);
      users = result.data;
      set({ users, tokenIsGood: true });
    } catch (e) {
      set({ tokenIsGood: false });
    }
  },
}));
