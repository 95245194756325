import React, { useState, useMemo } from 'react';
import { Button, Card, CardActions, CardHeader, CircularProgress, Divider, Typography } from '@material-ui/core';
import axios from 'axios';
import useCMS from '../../useCMS';
import { makeStyles } from '@material-ui/styles';
import { useApplicationStore } from 'services/ApplicationService';

const useStyles = makeStyles(theme => ({
  loader: {
    margin: "10px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  consoleError: {
    "background-color": "#0c0c0cz",
    "padding": "0px 12px",
    "font-family": '"Menlo", "DejaVu Sans Mono", "Liberation Mono", "Consolas", "Ubuntu Mono", "Courier New", "andale mono", "lucida console", monospace',
    "color": "crimson"
  },
  console: {
    "background-color": "#0c0c0cz",
    "padding": "0px 12px",
    "font-family": '"Menlo", "DejaVu Sans Mono", "Liberation Mono", "Consolas", "Ubuntu Mono", "Courier New", "andale mono", "lucida console", monospace',
    "color": "#cccccc"
  }
}));

const MeaSync = () => {
  const { endpoint, auth } = useCMS();
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [statusText, setStatusText] = useState(null);
  const [error, setError] = useState(null);
  const [statusCompleted, setStatusCompleted] = useState([]);
  const { status, setStatus } = useApplicationStore();

  const updateStatus = async () => {
    const result = await axios.get(`${endpoint}/mea/synchronize/status`, auth);
    return result.data;
  }

  const updateInterval = () => {
    const update = async () => {
      const result = await updateStatus();
      const updateText = () => {
        setStatusCompleted(result.completed);
        if (result.status !== 'stopped') {
          if (result.progress != null && result.length != null) {
            setStatusText(`synchronizing ${result.stage}: ${result.progress}/${result.length}...`);
          } else {
            setStatusText(`synchronizing ${result.stage}...`);
          }
        }
      };
      switch (result.status) {
        case "pending":
          updateText();
          setTimeout(update, 1000);
          break;
        case "error":
          setLoading(false);
          setError(result.error);
          break;
        case "stopped":
        case "done":
        default:
          updateText();
          setLoading(false);
          setStatus({isSynced: true})
          //setStatusText(null);
          break;
      }
      setInitialized(true);
    }
    update();
  }

  useMemo(async () => {
    const result = await updateStatus();
    if (result.status === "done") {
      setLoading(false);
    } else {
      updateInterval();
    }
  }, []);

  const classes = useStyles();

  const sync = async (type) => {
    setError(null);
    setLoading(true);
    try {
      await axios.get(`${endpoint}/mea/synchronize?${type}`, auth);
      updateInterval();
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.status === 428) {
        setError(e.response.data.message);
      }
    }
  }

  const stop = async () => {
    await axios.post(`${endpoint}/mea/stop`, {}, auth);
  }

  const syncEverything = async () => {
    sync('all');
  };

  const syncUsers = async () => {
    sync('users');
  };

  const syncAgenda = async () => {
    sync('agenda');
  };

  return (
    <Card>
      <form>
        <CardHeader title="MEA" subheader="Update the database with the data stored in MEA. The Management API access key needs to be placed within the MEA setup page." />
        <Divider />
        <CardActions>
          <Button color="primary" variant="outlined" onClick={syncEverything} disabled={loading}>
            All
          </Button>
          <Button color="primary" variant="outlined" onClick={syncUsers} disabled={loading}>
            Users
          </Button>
          <Button color="primary" variant="outlined" onClick={syncAgenda} disabled={loading}>
            Agenda
          </Button>
          <Button color="secondary" variant="outlined" onClick={stop} disabled={!initialized || !loading}>
            Stop
          </Button>
        </CardActions>
        <div style={{ display: (loading || statusText || error) && initialized ? "inherit" : "none" }}>
          <div style={{ backgroundColor: "black", paddingTop: "12px", paddingBottom: "12px" }}>
            {
              statusCompleted.map((s, i) => (
                <Typography key={s.line} className={classes.console} color="textSecondary" variant="body1">
                  {s.line}
                </Typography>)
              )
            }
            {loading && (<>
              <Typography className={classes.console} color="textSecondary" variant="body1">{statusText}</Typography>
              <div className={classes.loader}>
                <CircularProgress color="primary" size={32} />
              </div>
            </>)}
            {error && (<Typography className={classes.consoleError} color="textSecondary" variant="body1">Error: {error}</Typography>)}
          </div>
        </div>
      </form>
    </Card>
  );
};

export default MeaSync;
