import React, { useRef, useEffect } from 'react';
import { Button, Card, CardActions, CardHeader, Divider, Grid, List, Typography } from '@material-ui/core';

import { applicationApi } from 'services/ApplicationService';
import { eventApi } from 'services/EventService';
import { talkApi } from 'services/TalkService';
import { streamApi } from 'services/StreamService';
import { socketApi } from 'services/SocketService';

import Hls from 'hls.js';
import ListItem from '@material-ui/core/ListItem';

import keyVisual from './visual.png';

export const createVideo = (video, videoSrc, onManifestChanged) => {
  video.setAttribute('playsinline', '');
  video.setAttribute('autoplay', '');
  video.setAttribute('loop', '');
  video.setAttribute('muted', '');
  video.loop = true;
  video.playsinline = true;
  video.muted = true;
  video.controls = true;
  video.crossOrigin = 'anonymous';
  video.width = 1920 / 4;
  video.height = 1080 / 4;

  if (!videoSrc) return video;

  if (videoSrc.endsWith('m3u8')) {
    if (Hls.isSupported()) {
      var hls = new Hls();
      hls.loadSource(videoSrc);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, (unknown, manifest) => {
        onManifestChanged(manifest);
        video.play();
      });
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = videoSrc;
      video.addEventListener('loadedmetadata', () => {
        video.play();
      });
    }
  } else {
    const source = document.createElement('source');
    source.src = videoSrc;
    video.append(source);
  }

  return video;
};

const SecondScreen = props => {
  const { talk, event } = props;
  const { arenaSecondScreenLargeLogoUrl } = event;
  let speakers = (talk && talk.speakers) || [];


  const speakerTexts = speakers.map(speaker => {
    return (<div key={speaker.id} style={{margin: "10px", "display": "flex", "justifyContent": "center"}}>
      <img style={{width: "48px", minWidth: "48px", height: "48px", borderRadius: "24px"}} src={speaker.thumbnailUrl || '/images/avatars/placeholder.png'}/>
      <Typography style={{display: "inline", margin: "10px 10px", color: "white", width: "100%"}}>{speaker.forename} {speaker.surname}</Typography>
    </div>)
  });

  return (
    <div style={{padding: '24px', background: "linear-gradient(90deg, rgba(0, 28, 47, 1), rgba(1, 50, 83, 1))", width: "270px", height: "480px", display: "flex", justifyContent: "space-between", flexDirection: "column"}}>
      {!talk && arenaSecondScreenLargeLogoUrl && <img src={arenaSecondScreenLargeLogoUrl} width={"100%"} height={"100%"} />}
      {talk && <><Typography style={{color: "white", width: "100%"}}>{talk && talk.title}</Typography>
        <div>
          {speakerTexts}
        </div>
      </>}
    </div>
  );
};

const Director = () => {
  const [disabled, setDisabled] = React.useState(true);
  const [streams, setStreams] = React.useState([]);
  const [arenaTalks, setArenaTalks] = React.useState([]);
  const [stream, setStream] = React.useState(null);
  const [event, setEvent] = React.useState(null);
  const [newStream, setNewStream] = React.useState(undefined);
  const [arenaTalk, setArenaTalk] = React.useState(undefined);
  const [newArenaTalk, setNewArenaTalk] = React.useState(undefined);
  const [levels, setLevels] = React.useState(null);

  const { socket } = socketApi.getState();

  const applicationState = applicationApi.getState();
  const talkState = talkApi.getState();
  const eventState = eventApi.getState();
  const streamState = streamApi.getState();

  const onManifestChanged = manifest => {
    setLevels(manifest ? manifest.levels : null);
  }

  const ref = useRef();
  useEffect(() => {
    const doAsyncWork = async () => {
      const event = await eventState.pick();
      setEvent(event);
      const streams = await streamState.findAll();
      let arenaTalks = await talkState.findAll("arena");
      if(event && event.directorGroupId) {
        arenaTalks = arenaTalks.filter(t => {
          return t.groups.find(g => g == event.directorGroupId) != null;
        });
      }
      arenaTalks.sort((a, b) => {
        return new Date(a.startTime) - new Date(b.startTime);
      });
      setStreams(streams);
      setArenaTalks(arenaTalks);
      const result = await applicationState.find();
      const stream = result.arenaStream;
      const talk = result.arenaTalk;
      let url = null;
      const arenaTalk = talk ? arenaTalks.find(arenaTalk => arenaTalk.id === talk.id) : null;
      setArenaTalk(arenaTalk);
      if (stream) {
        url = stream.url;
        setStream(stream);
      }
      createVideo(ref.current, url, onManifestChanged);
      setDisabled(false);
    };
    doAsyncWork();
  }, []);

  const changeStream = async stream => {
    setDisabled(true);
    const id = stream ? stream.id : null;
    const url = stream ? stream.url : null;
    const quality = stream ? stream.quality : 0;
    await applicationState.update({ arenaStream: id });
    await socket.emit('admin/arenaLiveStream', {url, quality});
    setStream(stream);
    setDisabled(false);
  };

  const onStreamClick = async stream => {
    setNewStream(stream);
    createVideo(ref.current, stream.url, onManifestChanged);
  };

  const onStreamBlackClick = async () => {
    setNewStream(null);
    setLevels(null);
  };

  const onClickCancelStream = async () => {
    setNewStream(undefined);
    if(stream) {
      createVideo(ref.current, stream.url, onManifestChanged);
    }
  };

  const onClickApplyStream = async () => {
    await changeStream(newStream);
    setNewStream(undefined);
  };

  const streamButtons = streams.map(s => {
    return (
      <Button
        key={s.id}
        color="primary"
        variant="outlined"
        onClick={() => onStreamClick(s)}
        disabled={disabled || (stream && s.id === stream.id)}
      >
        {s.name}
      </Button>
    );
  });


  const changeTalk = async talk => {
    setDisabled(true);
    const id = talk ? talk.id : null;
    await applicationState.update({ arenaTalk: id });
    await socket.emit('admin/arenaTalk', id);
    setArenaTalk(talk);
    setDisabled(false);
  };

  const onClickArenaTalk = async talk => {
    setNewArenaTalk(talk);
  };

  const onClickClearTalk = async () => {
    //await changeTalk(null);
    setNewArenaTalk(null);
  };

  const onClickCancelTalk = async () => {
    setNewArenaTalk(undefined);
  };

  const onClickApplyTalk = async () => {
    await changeTalk(newArenaTalk);
    setNewArenaTalk(undefined);
  };

  const talkButtons = arenaTalks.map(s => {
    return (
      <ListItem key={s.id}>
        <Button
          color="primary"
          variant="outlined"
          disabled={disabled || (arenaTalk && s.id === arenaTalk.id)}
          onClick={() => onClickArenaTalk(s)}
        >
          {s.title}
        </Button>
      </ListItem>
    );
  });

  const showMainScreenPreview = newStream !== null && stream || newStream != null;

  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <Card>
          <CardHeader title="Main Screen Controls" />
          <Divider />
          <CardActions>
            {streamButtons}
            <Button
              color="primary"
              onClick={() => onStreamBlackClick()}
              disabled={disabled || stream == null}>
              Black
            </Button>
          </CardActions>
        </Card>
        <Card style={{ marginTop: '24px' }}>
          <CardHeader title="Second Screen Controls + Arena Live Badge" />
          <Divider />
          <CardActions>
            <List>
              <ListItem>
                <Button
                  color="primary"
                  onClick={() => onClickClearTalk()}
                  disabled={disabled || arenaTalk == null}>
                  None
                </Button>
              </ListItem>
              {talkButtons}
            </List>
          </CardActions>
        </Card>
      </Grid>
      <Grid item md={6} xs={12}>
        <Card>
          <CardHeader
            title="Main Screen Preview"
            subheader={newStream !== undefined ? (newStream !== null ? newStream.name : 'Select a stream on the left.') : (stream ? stream.name : 'Select a stream on the left.')}
            action={
              <>
              <Button
                style={{ margin: '10px 0 0 10px' }}
                color="primary"
                variant="outlined"
                disabled={newStream === undefined}
                onClick={() => onClickCancelStream()}
              >
                cancel
              </Button>
              <Button
                style={{ margin: '10px 10px 0 10px' }}
                color="primary"
                variant="outlined"
                disabled={newStream === undefined}
                onClick={() => onClickApplyStream()}
              >
                apply
              </Button>
              </>
            }
          />


          <Divider />
          <video
            style={{ display: showMainScreenPreview ? 'initial' : 'none', margin: '24px' }}
            ref={ref} />
          <div style={
            { display: !showMainScreenPreview ? 'block' : 'none',
              backgroundColor: "black",
              width: "480px",
              height: "270px",
              margin: '24px'}}/>
          {levels && <div style={{margin: "0 24px 24px 24px"}}><Typography variant={'h5'}>Levels</Typography>{levels.map((level, index) => {
            const resolution = level.width + "x" + level.height;
            return <Typography key={resolution}>{index}: {resolution}</Typography>;
          })}</div>}
        </Card>
        <Card style={{ marginTop: '24px' }}>
          <CardHeader
            title="Second Screen Preview"
            subheader={newArenaTalk !== undefined ? (newArenaTalk !== null ? newArenaTalk.title : 'Select a talk on the left.') : (arenaTalk ? arenaTalk.title : 'Select a talk on the left.')}
            action={
              <>
                <Button
                  style={{ margin: '10px 0 0 10px' }}
                  color="primary"
                  variant="outlined"
                  disabled={newArenaTalk === undefined}
                  onClick={() => onClickCancelTalk()}
                >
                  cancel
                </Button>
                <Button
                  style={{ margin: '10px 10px 0 10px' }}
                  color="primary"
                  variant="outlined"
                  disabled={newArenaTalk === undefined}
                  onClick={() => onClickApplyTalk()}
                >
                  apply
                </Button>
              </>
            }
          />
          <Divider />
          {(arenaTalk !== undefined || newArenaTalk !== undefined) && <SecondScreen talk={newArenaTalk === undefined ? arenaTalk : newArenaTalk} event={event} />}
        </Card>
      </Grid>
    </Grid>
  );
};

export default Director;
