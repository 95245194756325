import React, { useState } from 'react';
import axios from 'axios';
import { Button, Card, CardActions, CardContent, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import useCMS from '../useCMS';
import { DateTimePicker } from '@material-ui/pickers';
import moment from 'moment';

const download = require('downloadjs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const ExportActivity = () => {
  const classes = useStyles();
  const { endpoint, auth } = useCMS();
  const [isPending, setIsPending] = useState(false);
  const [responseText, setResponseText] = useState('');

  const coeff = 1000 * 60 * 5;
  const date = new Date();

  const [endDate, setEndDate] = useState(new Date(Math.round(date.getTime() / coeff) * coeff));
  const [startDate, setStartDate] = useState(moment(endDate).subtract(1, 'days').toDate());

  const handleActivityExport = async () => {
    setIsPending(true);
    setResponseText('');

    try {

      let utcStartDate = moment(startDate).utc();
      let utcEndDate = moment(endDate).utc();

      const result = await axios.post(`${endpoint}/activities/between`, {startDate: utcStartDate, endDate: utcEndDate}, {...auth, responseType: 'blob'});
      const blob = result.data;
      download(blob, "activity.xlsx", blob.type);

      setIsPending(false);
    } catch (error) {
      setResponseText(error.message);
      setIsPending(false);
    }
  };

  return (
    <>
      <div>
        <Grid item md={12} xs={12}>
          <Typography style={{ padding: '16px 0' }} variant="h3">Export Activities</Typography>
        </Grid>

        <Card className={classes.root}>
          <form autoComplete="off" noValidate>
            <Typography>Export the user activity to an excel sheet between two dates.</Typography>

            <CardContent>
              <Grid item md={6} xs={12}>
                <DateTimePicker
                  label={`Start`}
                  inputVariant="outlined"
                  value={startDate}
                  onChange={value => {
                    setStartDate(value)
                  }}
                  minutesStep={5}
                  fullWidth
                  clearable
                />
                </Grid>
              &nbsp;
                <Grid item md={6} xs={12}>
                <DateTimePicker
                  label={`End`}
                  inputVariant="outlined"
                  value={endDate}
                  onChange={value => {
                    setEndDate(value)
                  }}
                  minutesStep={5}
                  fullWidth
                  clearable
                />
              </Grid>
            </CardContent>

            <Divider />

            <CardActions>
              <Button color="primary" variant="contained" onClick={handleActivityExport} disabled={isPending}>
                Export Activities
              </Button>

              {responseText && <Typography>{responseText}</Typography>}
            </CardActions>
          </form>
        </Card>
      </div>
    </>
  );
};

export default ExportActivity;
