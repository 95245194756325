import { capitalize } from 'helpers';
import { modelApi } from 'services/ModelService';
import { TextField } from '@material-ui/core';
import React from 'react';

export function ModelTextField (params) {
  const { instance, attribute, attributeName } = params;

  const label = attribute.label ? attribute.label : capitalize(attributeName);

  const handleChange = event => {
    instance[attributeName] = event.target.value;
    modelApi.setState({ instance: { ...instance } });
  };

  let inputType;

  switch (attribute.type) {
    case 'integer':
      inputType = 'number';
      break;
    case 'password':
      inputType = 'password';
      break;
    default:
      inputType = 'text';
  }

  const disabled = attribute.disabled || false;

  return (
    <TextField
      fullWidth
      helperText={attribute.helperText}
      label={label}
      type={inputType}
      disabled={disabled}
      onChange={handleChange}
      value={instance[attributeName]}
      variant="outlined"
      multiline={attribute.type === 'text'}
      rows={5}
    />
  );
};
