import create from 'zustand';
import { cmsApi } from 'services/CmsService';
import axios from 'axios';
import userStore from 'storage/user';

function context() {
  const { endpoint } = cmsApi.getState();
  const token = userStore.getToken();
  const auth = { headers: { Authorization: `Bearer ${token}` } };
  return { endpoint, auth };
}

export const [useApplicationStore, applicationApi] = create((set, get) => ({
  instance: null,
  status: {
    platformStatus: null,
    isSynced: true,
    activeEvent: '',
    videoServiceProvider: 'zoom',
    isPreEventPageAssigned: false,
  },

  setStatus: (status) => {
    const currentStatus = get().status;

    set({
      status: {
        ...currentStatus,
        ...status
      }
    });
  },

  find: async () => {
    const { endpoint, auth } = context();
    const result = await axios.get(`${endpoint}/application`, auth);
    const instance = result.data;
    set({ instance });
    return instance;
  },

  update: async (update) => {
    const { endpoint, auth } = context();
    const result = await axios.put(`${endpoint}/application`, update, auth);
    const instance = result.data;
    set({ instance });
    return instance;
  },

}));
