import React from 'react';
import { modelApi } from 'services/ModelService';
import pluralize from 'pluralize';
import { capitalize } from 'helpers';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField } from '@material-ui/core';


export function RelationField(params) {
  const { instance, attribute, attributeName } = params;

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options != null && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const state = modelApi.getState();
      const options = attribute.options ? await attribute.options() : await state.options(pluralize(attribute.model));
      if (active) {
        setOptions(options.length == 0 ? null : options);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, attribute]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const label = attribute.label ? attribute.label : capitalize(attributeName);

  const handleChange = (event, option) => {
    instance[attributeName] = option;
    modelApi.setState({ instance: { ...instance } });
  };

  const disabled = !!attribute.disabled;

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={option => {
        if (!option.id) {
          return 'none';
        }
        return attribute.optionLabel ? attribute.optionLabel(option) : option.id.toString();
      }}
      options={options || []}
      loading={loading}
      value={instance[attributeName]}
      disabled={disabled}
      onChange={handleChange}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
