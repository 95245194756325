import create from 'zustand';
import { cmsApi } from 'services/CmsService';
import axios from 'axios';
import userStore from 'storage/user';
import { eventApi } from 'services/EventService';

function context() {
  const { endpoint } = cmsApi.getState();
  const token = userStore.getToken();
  const auth = { headers: { Authorization: `Bearer ${token}` } };
  return { endpoint, auth };
}

export const [useTalkStore, talkApi] = create((set, get) => ({
  findAll: async (location) => {
    const event = await eventApi.getState().pick();
    if(!event) {
      return [];
    }
    const { endpoint, auth } = context();
    const result = await axios.get(`${endpoint}/talks?event=${event.id}&location=${location}&isHidden=false`, auth);
    return result.data;
  },

}));
