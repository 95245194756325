import { capitalize } from 'helpers';
import { modelApi } from 'services/ModelService';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';


export function BooleanField(params) {
  const { instance, attributeName, attribute } = params;

  const label = attribute.label ? attribute.label : capitalize(attributeName);
  const disabled = attribute.disabled || false;

  const handleChange = (event, value) => {
    instance[attributeName] = value === true;
    modelApi.setState({ instance: { ...instance } });
  };

  return (
    <FormControlLabel
      disabled={disabled}
      control={
        <Checkbox
          checked={instance[attributeName]}
          onChange={handleChange}
          disabled={disabled}
          color="primary" />
      }
      label={label}
    />
  );
};
