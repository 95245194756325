import React from 'react';

import { ZoomUserField } from 'components/ZoomUserField';
import { TeamsUserField } from 'components/TeamsUserField';
import { GroupIdField } from 'components/GroupIdField';

import { modelApi } from 'services/ModelService';
import { eventApi } from 'services/EventService';
import { userApi } from 'services/UserService';

const images = {};

function importAll (r) {
  r.keys().forEach(key => images[key.substring(2, key.length - 4)] = r(key));
}

importAll(require.context('assets/images', true, /\.png$/));

const hiddenInList = {
  form: false,
  list: true,
};

const hiddenInForm = {
  form: true,
  list: false,
};

function createEventAttribute() {
  return {
    default: async () => {
      const state = eventApi.getState();
      return await state.pick();
    },
    valueInTable: (instance, key) => {
      return instance[key] ? instance[key].name : '-';
    },
    options: async () => {
      const state = modelApi.getState();
      return await state.options('events', 'isHidden=false');
    },
    optionLabel: option => option.name,
  };
}

function createHeadlineAttribute() {
  return {
    valueInTable: (instance, key) => {
      return instance[key] ? instance[key].headline : '-';
    },
    optionLabel: option => option.headline,
  };
}

function createTalkAttribute() {
  return {
    valueInTable: (instance, key) => {
      return instance[key] ? instance[key].title : '-';
    },
    optionLabel: option => option.title,
  };
}

function createRelationAttributeWithName() {
  return {
    valueInTable: (instance, key) => {
      return instance[key] ? instance[key].name : '-';
    },
    optionLabel: option => option.name,
  };
}

function createUserAttribute(filter) {
  return {
    default: () => {
      const state = userApi.getState();
      return state.user;
    },
    options: async () => {
      const state = modelApi.getState();
      const users = await state.options('users', filter);
      return users.filter(u => u.searchName !== null);
    },
    optionLabel: option => {
      return option.id != null ? option.searchName : 'none';
    },
    valueInTable: (instance, key) => {
      return instance[key] ? instance[key].searchName : '-';
    },
  };
}

function createAssetAttribute(filter, media) {
  return {
    options: () => {
      const state = modelApi.getState();
      return state.options('assets', 'filter_eq=' + filter);
    },
    hidden: {
      list: media == null,
    },
    valueInTable: (instance, key) => {
      return media == null ? null : { media, asset: instance[key] };
    },
    optionLabel: option => {
      if(option.fileName) {
        return option.fileName;
      }

      return option.url;
    },
  };
}

function createAssetSchema(filter, media, urlHidden) {
  return {
    apiName: 'assets',
    useFormData: true,
    filter: 'filter_eq=' + filter,
    searchFields: ['fileName'],
    attributes: {
      file: { type: 'file', hidden: { list: true } },
      url: {
        hidden: urlHidden || {
          form: true,
        },
        disabled: true,
        valueInTable: instance => {
          return { media, asset: instance };
        },
      },
      fileName: {
        hidden: false,
        disabled: true,
      },
      filter: {
        hidden: true,
        default: () => filter,
      },
    },
  };
}

const config = {
  "pre-event-pages": {
    searchFields: ['name'],
    sortedGroups: [
      { name: 'Go-Live In', attributes: ['startDate', 'goLiveInText'] },
      { name: 'What\'s Waiting For You', attributes: ['inspiringLiveTalks', 'inspiringLiveTalksHeadline', 'inspiringLiveTalksSubline', 'collaborativeGroupSessions', 'collaborativeGroupSessionsHeadline', 'collaborativeGroupSessionsSubline', 'newConnections', 'newConnectionsHeadline', 'newConnectionsSubline'] },
      { name: 'The Event Program', attributes: ['agendaVisible', 'agendaDownloadLinkVisible'] },
      { name: 'Checklist', attributes: ['checklistMainHeadline', 'checklistHeadline1', 'checklistHeadline1Text1', 'checklistHeadline1Text2', 'checklistHeadline1Text3', 'checklistHeadline2', 'checklistHeadline2Text1', 'checklistHeadline2Text2', ] },
      { name: 'Zoom & Miro', attributes: ['zoomLinkVisible', 'zoomLink', 'miroLinkVisible', 'miroLink'] },
      { name: 'Backgrounds', attributes: ['backgroundsVisible'] },
      { name: 'Footer', attributes: ['pleaseNoteText', 'anyQuestionsMail'] },
    ],
    attributes: {
      logo: {...createAssetAttribute('resource-images', 'image'), tooltip: "Use 558x558 for best results."},
      startDate: { tooltip: "Point in time when the countdown finishes. Users that lurk on the pre-event page are redirected into the event. Please note that you set the date in your local time, not the event time zone." },
      goLiveInText: { hidden: hiddenInList },
      inspiringLiveTalks: { hidden: hiddenInList, tooltip: <img width="250px" src={images.inspiringLiveTalks}/> },
      inspiringLiveTalksHeadline: { hidden: hiddenInList },
      inspiringLiveTalksSubline: { hidden: hiddenInList },
      collaborativeGroupSessions: { hidden: hiddenInList, tooltip: <img width="250px" src={images.collaborativeGroupSessions}/> },
      collaborativeGroupSessionsHeadline: { hidden: hiddenInList },
      collaborativeGroupSessionsSubline: { hidden: hiddenInList },
      newConnections: { hidden: hiddenInList, tooltip: <img width="250px" src={images.newConnections}/> },
      newConnectionsHeadline: { hidden: hiddenInList },
      newConnectionsSubline: { hidden: hiddenInList },
      agendaVisible: { hidden: hiddenInList },
      agendaDownloadLinkVisible: { hidden: hiddenInList },
      checklistMainHeadline: { hidden: hiddenInList },
      checklistHeadline1: { hidden: hiddenInList },
      checklistHeadline1Text1: { hidden: hiddenInList },
      checklistHeadline1Text2: { hidden: hiddenInList },
      checklistHeadline1Text3: { hidden: hiddenInList },
      checklistHeadline2: { hidden: hiddenInList },
      checklistHeadline2Text1: { hidden: hiddenInList },
      checklistHeadline2Text2: { hidden: hiddenInList },
      zoomLinkVisible: { hidden: hiddenInList },
      zoomLink: { hidden: hiddenInList },
      miroLinkVisible: { hidden: hiddenInList },
      miroLink: { hidden: hiddenInList },
      backgroundsVisible: { hidden: hiddenInList },
      pleaseNoteText: { hidden: hiddenInList },
      anyQuestionsMail: { hidden: hiddenInList },
    }
  },
  events: {
    searchFields: ['name'],
    createHidden: true,
    deleteHidden: true,
    sortedGroups: [
      { name: 'Pre-Event Page', attributes: ['preEventAgendaGroupId'] },
      { name: 'Users', attributes: ['supportUser', 'userVisibilityGroupId', 'userSpeakerGroupId'] },
      { name: 'Profiles', attributes: ['showAbout', 'showCompany', 'showJobTitle'] },
      { name: 'Chat', attributes: ['videoChat'] },
      { name: 'Arena', attributes: ['directorGroupId', 'arenaSecondScreenInteractive', 'arenaSecondScreenSmallLogo', 'arenaSecondScreenLargeLogo'] },
      { name: 'Academy & Think Tank', attributes: ['academy', 'thinkTank', 'invisibleInNotAssignedGroupId'] },
      { name: 'Enhancers', attributes: ['playground', 'spa', 'cafe'] },
      { name: 'Development', attributes: ['removeGroupId', 'addGroupId0', 'addGroupId1', 'addGroupId2', 'addGroupId3'] },
    ],
    attributes: {
      conventionId: { hidden: hiddenInForm },
      name: { disabled: true },
      logo: {...createAssetAttribute('resource-images', 'image'), tooltip: "Use 558x558 for best results. Relevant for the login screen and possibly the pre-event page."},
      preEventAgendaGroupId: {
        hidden: hiddenInList,
        component: GroupIdField,
      },
      timezone: { hidden: hiddenInList, disabled: true },
      groupName: { hidden: hiddenInList, disabled: true },
      groups: { hidden: hiddenInList },
      library: { ...createRelationAttributeWithName(), hidden: hiddenInList },
      supportUser: createUserAttribute('role.type=supporter'),
      userVisibilityGroupId: {
        hidden: hiddenInList,
        component: GroupIdField,
      },
      userSpeakerGroupId: {
        hidden: hiddenInList,
        component: GroupIdField,
      },
      invisibleInNotAssignedGroupId: {
        hidden: hiddenInList,
        component: GroupIdField,
      },
      offlineWispCount: {
        hidden: hiddenInList
      },
      removeGroupId: {
        hidden: hiddenInList,
        component: GroupIdField,
      },
      showAbout: { hidden: hiddenInList },
      showCompany: { hidden: hiddenInList },
      showJobTitle: { hidden: hiddenInList },
      skipIntro: { hidden: hiddenInList },
      videoChat: { hidden: hiddenInList, tooltip: <img src={images.videoChat}/> },
      directorGroupId: { hidden: hiddenInList, component: GroupIdField },
      arenaSecondScreenInteractive: { hidden: hiddenInList, tooltip: <img src={images.arenaSecondScreenInteractive}/> },
      arenaSecondScreenSmallLogo: {...createAssetAttribute('resource-images', 'image'), hidden: hiddenInList, tooltip: <><img src={images.arenaSecondScreenSmallLogo}/><div>Use 173x308 for best results.</div></>},
      arenaSecondScreenLargeLogo: {...createAssetAttribute('resource-images', 'image'), hidden: hiddenInList, tooltip: <><img src={images.arenaSecondScreenLargeLogo}/><div>Use 1080x1920 for best results.</div></>},
      academy: { hidden: hiddenInList },
      thinkTank: { hidden: hiddenInList },
      playground: { ...createRelationAttributeWithName(), hidden: hiddenInList },
      spa: { hidden: hiddenInList },
      cafe: { hidden: hiddenInList },
      addGroupId0: {
        hidden: hiddenInList,
        component: GroupIdField,
      },
      addGroupId1: {
        hidden: hiddenInList,
        component: GroupIdField,
      },
      addGroupId2: {
        hidden: hiddenInList,
        component: GroupIdField,
      },
      addGroupId3: {
        hidden: hiddenInList,
        component: GroupIdField,
      },
      isHidden: {
        hidden: true,
      },
      isDeleted: {
        hidden: true,
      },
    },
  },
  teams: {
    attributes: {
      jwt: {
        disabled: true,
      }
    }
  },
  users: {
    createHidden: true,
    deleteHidden: true,
    filter: 'isDeleted_eq=false',
    attributes: {
      username: {
        hidden: true,
      },
      role: {
        hidden: {
          form: () => {
            const state = userApi.getState();
            const userRole = state.user.role.type;
            // only the admin can change the role
            const isAdmin = userRole === 'admin';
            return !isAdmin;
          },
          list: false,
        },
        options: async () => {
          const userState = userApi.getState();
          const userRole = userState.user.role.type;
          const modelState = modelApi.getState();
          let result = await modelState.options('users-permissions/roles');
          let roles = result.roles;
          roles = roles.filter(role => {
            return role.type !== 'public';
          });
          if (userRole !== 'admin') {
            roles = roles.filter(role => {
              return role.type !== 'admin';
            });
          }
          return roles;
        },
        optionLabel: option => option.type,
        valueInTable: (instance, key) => {
          return instance[key] ? instance[key].type : '-';
        },
      },
      confirmed: {
        hidden: true,
      },
      password: {
        hidden: true,
      },
      provider: {
        hidden: true,
      },
      resetPasswordToken: {
        hidden: true,
      },
      blocked: {
        hidden: hiddenInList,
      },
      zoomUserId: {
        hidden: false,
        component: ZoomUserField,
      },
      teamsUserId: {
        hidden: false,
        component: TeamsUserField,
      },
      personId: {
        disabled: true,
      },
      forename: {
        disabled: true,
      },
      surname: {
        disabled: true,
      },
      company: {
        hidden: hiddenInList,
        disabled: true,
      },
      title: {
        hidden: hiddenInList,
        disabled: true,
      },
      city: {
        hidden: hiddenInList,
        disabled: true,
      },
      description: {
        hidden: hiddenInList,
        disabled: true,
      },
      linkedInUrl: {
        hidden: hiddenInList,
        disabled: true,
      },
      xingUrl: {
        hidden: hiddenInList,
        disabled: true,
      },
      thumbnailUrl: {
        hidden: hiddenInList,
        disabled: true,
      },
      groups: {
        hidden: true,
      },
      isOnline: {
        hidden: true,
      },
      isSpeaker: {
        hidden: true,
      },
      activity: {
        hidden: true,
      },
      isDeleted: {
        hidden: true,
      },
      isHidden: {
        hidden: true,
      },
      searchName: {
        hidden: true,
      },
      hasNotifications: {
        hidden: true,
      },
      session: {
        hidden: true,
      },
      refreshToken: {
        hidden: true,
      },
    },
    searchFields: ['searchName'],
  },
  'resource-hubs': {
    searchFields: ['name'],
    attributes: {},
  },
  'teaser-events': {
    searchFields: ['description'],
    attributes: {
      image: {...createAssetAttribute('resource-images', 'image'), tooltip: "16:9 images are recommended, for example 1920 x 1080."},
    },
  },
  resources: {
    searchFields: ['headline'],
    sortedGroups: [{ name: 'Text', attributes: ['headline', 'subline'] }],
    attributes: {
      category: createHeadlineAttribute(),
      teaser: createAssetAttribute('resource-images', 'image'),
      asset: createAssetAttribute(
        'resource-videos&filter_eq=resource-images&filter_eq=resource-podcasts&filter_eq=resource-others&filter_eq=resource-documents&filter_eq=resource-streams',
        null,
      ),
    },
  },
  'resource-images': createAssetSchema('resource-images', 'image'),
  'resource-videos': createAssetSchema('resource-videos', 'video'),
  'resource-streams': {
    apiName: 'assets',
    useFormData: true,
    filter: 'filter_eq=resource-streams',
    searchFields: ['fileName'],
    attributes: {
      fileName: {
        label: "Name",
        hidden: false,
        disabled: false,
      },
      url: {
        hidden: {
          form: false,
        },
        disabled: false,
      },
      filter: {
        hidden: true,
        default: 'resource-streams',
      },
    },
  },
  'resource-audios': createAssetSchema('resource-podcasts', 'audio'),
  'resource-others': createAssetSchema('resource-others', null, true),
  'resource-documents': createAssetSchema('resource-documents', null, true),
  'resource-categories': {
    searchFields: ['headline'],
    attributes: {
      hub: { ...createRelationAttributeWithName(), label: "Library" }
    },
  },
  'playground-resources': {
    searchFields: ['name'],
    attributes: {
      playground: createRelationAttributeWithName(),
    },
  },
  streams: {
    attributes: {
      quality: {
        label: "Arena - Quality Level"
      },
    }
  },

  // single types
  application: {
    attributes: {
      isSynced: { disabled: true, hidden: true, },
      videoCallService: { hidden: false, },
      defaultEvent: createEventAttribute(),
      preEventPage: createRelationAttributeWithName(),
      arenaStream: { ...createRelationAttributeWithName(), disabled: true },
      arenaTalk: { ...createTalkAttribute(), disabled: true },
    },
  },
};

export default config;
