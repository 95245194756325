import { capitalize } from 'helpers';
import { modelApi } from 'services/ModelService';
import { TextField } from '@material-ui/core';
import React from 'react';
import { Autocomplete } from '@material-ui/lab';

export function EnumerationField (params) {
  const { instance, attribute, attributeName } = params;

  const label = attribute.label ? attribute.label : capitalize(attributeName);

  const [open, setOpen] = React.useState(false);

  const handleChange = (event, option) => {
    instance[attributeName] = option;
    modelApi.setState({ instance: { ...instance } });
  };

  const options = attribute.enum;

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={options}
      value={instance[attributeName]}
      onChange={handleChange}
      renderInput={params => <TextField {...params} label={label} variant="outlined" />}
    />
  );
};
